<template>
  <div id="agency">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />申报详情
          </van-col>
          <!-- v-if="company.status == '0'" -->
          <van-col
            v-if="company.status == 'approving'"
            span="10"
            class="header-title"
            style="text-align: right"
            @click="onStatusClick(1)"
          >
            审核通过
          </van-col>
          <van-col
            v-if="company.status == 'approving'"
            span="4"
            class="header-title"
            style="text-align: right; color: #de350b"
            @click="onStatusClick(2)"
          >
            拒绝
          </van-col>
          <!-- <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          v-if="company.status == '1'"
          @click="onDeleteClick"
        >
          删除
        </van-col> -->
        </van-row>
      </div>
    </van-sticky>
    <van-dialog
      v-model="showReject"
      title="审核拒绝"
      show-cancel-button
      :beforeClose="beforeClose"
    >
      <van-field
        v-model="reject_reason"
        label="拒绝原因"
        placeholder="请输入拒绝原因"
      />
    </van-dialog>
    <div class="agency-wrapper">
      <div class="agency-container">
        <!-- <van-image
          class="agency-avatar"
          round
          :src="company.avatar ? company.avatar : ''"
          ><template v-slot:error
            ><span style="font-size: 10px">暂无头像</span></template
          ></van-image
        > -->

        <van-row>
          <van-col span="24">
            <div class="main-text">{{ company.travel_company_name }}</div>
          </van-col>
          <van-col span="24">
            <div class="star-text">
              <van-rate
                v-model="company.star"
                :size="25"
                color="#FFAA00"
                void-icon="star"
                void-color="#eee"
                readonly
                allow-half
              />
            </div>
          </van-col>

          <van-col span="24">
            <div class="agency-text">描述：{{ company.description }}</div>
          </van-col>
          <van-col span="24">
            <div class="agency-text">审核状态：{{ company.status_text }}</div>
          </van-col>
          <van-col span="24">
            <div class="agency-text">创建时间：{{ company.created_at }}</div>
          </van-col>
          <!-- <van-col span="24">
            <div class="agency-text">
              更新时间：{{company.updated_at}}
            </div>
          </van-col> -->

          <van-col span="24"
            ><div class="agency-text">
              照片：<van-uploader
                v-if="fileList1.length > 0"
                :deletable="false"
                readonly
                v-model="fileList1"
                :max-count="1"
              />
              <span v-else>未完善</span>
            </div></van-col
          >
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";

export default {
  name: "start-item",
  data() {
    return {
      showReject: false,
      reject_reason: "",

      company: {},
      fileList1: [],
    };
  },
  methods: {
    // 返回上一级
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取详情
    async getDetail(id) {
      const res = await this.$apis.get_star_mes(id);
      console.log(res);
      this.company = res.data;
      if (res.data.images && res.data.images[0]) {
        res.data.images.forEach((it) => {
          this.fileList1.push({ url: it });
        });
      }
    },

    // 拒绝原因弹窗-暂时不用
    beforeClose(action, done) {
      if (action == "confirm") {
        if (!this.reject_reason) {
          this.$toast("请输入拒绝原因");
          done(false);
          return false;
        }
        this.examine(2);
      }
      if (action == "cancel") {
        this.showReject = false;
      }
    },
    // 申请和拒绝弹窗提示
    onStatusClick(status) {
      // if(status==2){
      //   this.showReject = true
      //   return false
      // }
      const _self = this;
      Dialog.confirm({
        title: "温馨提示",
        message: status == 1 ? "确认通过审核吗" : "确认拒绝吗",
      })
        .then(() => {
          _self.examine(status);
        })
        .catch(() => {});
    },
    // 申请和拒绝方法
    async examine(i) {
      const id = this.$route.query.id;
      let params = {
        company_star_list: {
          status: "",
        },
      };
      if (i === 1) {
        // 通过
        params.company_star_list.status = "approve_pass";
      } else if (i === 2) {
        // 不通过
        params.company_star_list.status = "approve_deny";
      }
      const res = await this.$apis.edit_star_mes(id, params);
      if (res.status == 200) {
        Toast("操作成功");
        // this.getDetail(id);
        this.$router.go(-1);
        // 弹窗相关
        // this.showReject = false
        // this.reject_reason = ""
      }
    },

    // 删除相关-暂时不用
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该旅行社吗?",
      })
        .then(() => {
          _self.delete_travel_company();
        })
        .catch(() => {});
    },
    async delete_travel_company() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_travel_company(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
  },
  components: {},
  created() {
    const id = this.$route.query.id;
    if (id) {
      this.getDetail(id);
    } else {
      Toast("参数错误");
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>
.apply-line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.apply-line-1 {
  margin-left: 1rem;
  width: 4rem;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 26px;
  margin-top: 6px;
  font-weight: 600;
  letter-spacing: 1px;
  /* background: rgb(52, 239, 24); */
  color: rgb(52, 239, 24);
}
.apply-line-2 {
  margin-left: 1rem;
  width: 4rem;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 26px;
  margin-top: 6px;
  font-weight: 600;
  letter-spacing: 1px;
  /* background: rgb(232, 66, 66); */
  color: rgb(232, 66, 66);
}
</style>
<style scoped>
#agency {
  height: 100vh;
  /* overflow-y: scroll; */
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.agency-wrapper {
  padding: 0.4rem;
  position: relative;
}
.agency-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  height: 82vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}
.agency-avatar {
  position: absolute;
  top: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  z-index: 100;
}
.agency-avatar img {
  width: 100%;
  height: 100%;
}

.agency-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 200px;
  height: 200px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 20px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 17px;
  border-radius: 17px;
  height: 34px;
  width: 80px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  color: #40a9ff;
}
.mobile_icon {
  font-size: 0.5rem;
}
.van-image__img,
.van-uploader {
  vertical-align: middle !important;
  z-index: 1;
}
</style>
